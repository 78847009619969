// import hoverintent from 'hoverintent';

export default () => {

    const debug_menu = false;

    // all top level list items
    const topLevelmenuItems = document.querySelectorAll('ul.mainmenu > li');
    
    if (!topLevelmenuItems.length) {
        return;
    }
    // the list item containing the megamenu
    const toggleParent = document.querySelector('li.megamenu-item');
    // the toggle link for the megamenu
    const toggleEl = document.querySelector('a.products-nav-toggle');
    // the megamenu itself
    const megamenuEl = document.querySelector('.products-nav-submenu');
    // hover timer
    let hoverInterval, menuEnterTimer, menuLeaveTimer;
    let showDelay = 450, hideDelay = 800;

    if (debug_menu) {
        toggleParent.classList.add('hover');
        return;
    }

    const isHovered = function() {
        return document.querySelector(".products-nav-submenu:hover") != null || document.querySelector("a.products-nav-toggle:hover") != null;
    }

    if (!toggleEl) {
        return;
    }
    
    toggleEl.addEventListener('mouseenter', function() {
        clearTimeout(menuLeaveTimer);
        menuEnterTimer = setTimeout(function() {
            toggleParent.classList.add('hover');
        }, showDelay);
    });

    // triggered when user's mouse leaves the menu item
    toggleEl.addEventListener('mouseleave', function() {
        clearTimeout(menuEnterTimer);
        menuLeaveTimer = setTimeout(function() {
            if (!isHovered()) {
                toggleParent.classList.remove('hover');
            }
        }, hideDelay);
    });

    // utility to check if any submenu is hovered

    // update the hoverInterval timer anytime a submenu is hovered
    const hideMenu = function() {
        hoverInterval = window.setTimeout(
            function() {
                if (!isHovered()) {
                    toggleParent.classList.remove('hover');
                }
            }, 500);
    }

    // clear the hoverInterval timer on any submenu mouseleave
    megamenuEl.onmouseleave = function(event) {
        clearTimeout( hoverInterval )
        hideMenu();
    }

    // extend the timeout for the megamenu
    topLevelmenuItems.forEach(function (el,index) {
        const exclude = el.classList.contains("megamenu-item");
        if (!exclude) {
            el.addEventListener("mouseover", hideMenu, false);
        }
    });

}